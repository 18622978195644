import { useRef } from "react";

/**
 * If no id supplied a new unique id is returned and ensured to be the same over all renders
 * @param {String} id - an id that if defined will be returned
 * @returns {String}
 */
const useId = (id) => {
  const refId = useRef(id);
  refId.current =
    refId.current ||
    "id-" + Date.now() + "-" + Math.round(Math.random() * 99999);
  return refId.current;
};

export default useId;