import React from "react";
import classNames from "classnames";
import useId from "web/core/tools/useId";

export const BlockItem = ({ name, children, ...props }) => {
  const id = useId();
  return (
    <div {...props} key={`${id}-${name}`}>{children}</div>
  );
}

const Block = ({ children, className, ...props }) => {
  const id = useId();
  const classes = classNames('block-cols', {
    [`${className}`]: className,
  });

  return (
    <div {...props} className={classes} key={`${id}-block-cols`}>
      {children}
    </div>
  );
};

export default Block;
