import renderChildNodes from "./renderChildNodes";
import Link from "./Components/Link";
import Block, { BlockItem } from "./Components/Block";
import React, { useMemo } from "react";
import classNames from "classnames";

export const defaultComponentsMap = {
  a: Link,
  gauche: ({
    className,
    ...props
  }) => {
    const classes = classNames('col-left', {
      [`${className}`]: className,
    });
    return (
      <BlockItem {...props} className={classes} name={"gauche"} />
    );
  },
  droite: ({
    className,
    ...props
  }) => {
    const classes = classNames('col-right', {
      [`${className}`]: className,
    });
    return (
      <BlockItem {...props} className={classes} name={"droite"} />
    );
  },
  "colonne1-2": Block,
};

const CustomWysiwyg = ({ content, componentsMap }) => {
  const componentsMapWithDefaultComponents = useMemo(() => {
    return {
      ...defaultComponentsMap,
      ...(componentsMap || {}),
    };
  }, [componentsMap]);

  return renderChildNodes(
    content.childNodes,
    content.data,
    componentsMapWithDefaultComponents
  );
};

export default CustomWysiwyg;
